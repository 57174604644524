<template>
  <div id="app" :class="typeof weather.main != 'undefined' && weather.main.temp > 30 ? 'warm' : ''">
    <main>
      <div class="search">
        <input type="text" 
        class="search-bar" 
        placeholder="Search a location and hit Enter!"
        v-model="query"
        @keypress="getWeather"/>
      </div>
      <div class="weather-div" v-if="typeof weather.main!='undefined'">
        <div class="location-box">
          <div class="location">{{weather.name}}, {{weather.sys.country}}</div>
          <div class="date">{{dateBuilder()}}</div>
        </div>
        <div class="weather-box">
          <div class="temp">{{Math.round(weather.main.temp)}}°C</div>
          <div class="weather">{{weather.weather[0].main}}</div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>

export default {
  name: 'App',
  data (){
    return {
      api_key: '1a86ee723aaf304baa9caeb425708612',
      url_base: 'https://api.openweathermap.org/data/2.5/',
      query:'',
      weather:{}
    }
  },
  methods:{
    getWeather(e){
      if(e.key == "Enter"){
        fetch(`${this.url_base}weather?q=${this.query}&units=metric&APPID=${this.api_key}`)
        .then(res => {
          return res.json();
        }).then(this.setWeather);
      }
    },
    setWeather(results){
      this.weather = results;
    },
    dateBuilder(){
      let d = new Date();
      let months = ["Jan", "Feb", "Mar", "Apr", "May", "June", 
      "July", "Aug", "Sept", "Oct", "Nov", "Dec"];
      let days = ["Sunday", "Monday", "Tuesday", 
      "Wednesday", "Thursday", "Friday", "Saturday"];

      let day = days[d.getDay()];
      let date = d.getDate();
      let month = months[d.getMonth()];
      let year = d.getFullYear();

      return `${day} ${date} ${month} ${year}`;
    }
  }
}
</script>

<style>
* {
 margin:0;
 padding:0;
 box-sizing: border-box;
 font-family: 'Montserrat', sans-serif;
}


#app{
  background-image: url('./assets/cool-bg.jpg');
  background-size: cover;
  background-position: bottom;
  transition: 0.4s;
}

#app.warm {
  background-image: url('./assets/warm-bg.jpg');
  background-size: cover;
  background-position: bottom;
  transition: 0.4s;
}

main{
  min-height:100vh;
  padding: 25px;
  background-image: linear-gradient(to bottom, rgba(0,0,0,0.25), rgba(0,0,0,0.75));
}

.search{
  width:100%;
  margin-bottom:30px;
}

.search .search-bar{
  display:block;
  width:100%;
  padding:15px;
  color: #313131;
  font-size:20px;
  appearance: none;
  border:none;
  outline:none;
  background: none;
  background-color: rgba(255,255,255,0.8);
  transition: 0.4s;
  box-shadow: 0px 0px 8px rgba(0,0,0,0.25);
}

.search .search-bar:focus{
  background-color: rgba(255,255,255);
  border-radius: 16px 16px 16px 16px;
}

.location-box .location{
  color: #FFF;
  font-size:32px;
  font-weight: 500;
  text-align:center;
  text-shadow:1px 3px rgba(0,0,0,0.25);
}

.location-box .date{
  color: #FFF;
  font-size:20px;
  font-weight: 300;
  font-style:italic;
  text-align:center;
  text-shadow:1px 3px rgba(0,0,0,0.25);
}

.weather-box{
  text-align:center;
}

.weather-box .temp{
  display:inline-block;
  padding:10px 25px;
  color:#FFF;
  font-size:100px;
  font-weight:900;
  text-shadow: 3px 6px rgba(0,0,0,0.25);
  background-color:rgba(255,255,255,0.25);
  border-radius:16px;
  margin:30px 0px;

  box-shadow:3px 6px rgba(0,0,0,0.25);
}

.weather-box .weather{
  color:#FFF;
  font-size:50px;
  font-weight:700;
  text-align:center;
  font-style:italic;
  text-shadow: 2px 4px rgba(0,0,0,0.25);
}
</style>
